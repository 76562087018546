import React from 'react';

const items = [
    {
        question: "How do you get paid?",
        answer: "We are primarily paid through an advisory fee for investments we manage, and/or a flat dollar amount for planning. Our advisory fee is a percentage of the money you invest with us. It starts at 1% and decreases at certain breakpoints. The percentage is expressed as an annual amount, but paid each quarter and comes directly out of your investment account. For example, if you hire us to manage $600k for you, our fee will be 1% of that or $6k annually. 25% of that, or $1,500, will be paid to us from your account each quarter. Our financial planning fee is a flat dollar amount quoted at or soon after our initial meeting and is typically around $3,600 for the initial planning work and help with implementation and additional questions for that first year. This can be paid up front or divided into monthly payments over the year. Ongoing planning beyond year 1 may be discounted if we see that our time commitment has reduced enough to warrant a lower fee. In rare cases we receive commissions on investments or products. If we recommend an investment or product that will pay us a commission, we will let you know before you move forward, and we will not charge an advisory fee on any assets in a commission product."
    },
    {
        question: "Are you affiliated with a major financial firm?",
        answer: 'As independent advisors, we are not employed by any bank, insurance company, or investment company. This gives us the freedom to design our services and choose your investments based on what is best for you. We are not beholden to quotas or corporate agendas. However, we have partnered with Kestra Financial for technology, compliance, and operational support. We also partner with National Financial Services ("NFS"), which is a Fidelity Subsidiary, for asset custody and clearing services. If you hire us to manage your investments, you will receive statements, disclosures, and tax forms from Kestra and NFS, though we will be your advisors and your points of contact for any questions or advice. If we ever see that Kestra or NFS are not providing the best services for us and our clients, we have the freedom to choose new partners.'
    },
    {
        question: "What experience or credentials do you have?",
        answer: "Our professionals have decades of experience as financial advisors as well as experience from prior careers in public accounting, mortgage lending, institutional asset management, and oil & gas. To build our expertise and distinguish ourselves from the many other financial professionals out there, our advisors have obtained the top credentials in the financial industry including CERTIFIED FINANCIAL PLANNER™ (CFP®), Chartered Financial Analyst (CFA), and Certified Public Accountant (CPA)."
    },
    {
        question: "How often do you meet with clients?",
        answer: "When we start working with new clients, we often meet 3 or 4 times in those first few months. After settling into a plan of action and investment strategy, most clients prefer to meet 2 to 4 times per year. In times of transition or change we may meet more often, and in steady times we may meet less often. We let you decide how often you would like to meet."
    },
    {
        question: "Do you work with clients outside of Tyler, TX?",
        answer: "Yes. We currently have clients in more than 20 States and a few foreign countries."
    },
    {
        question: "Do you offer free consultations?",
        answer: "Yes. If you have additional questions and want to know whether we are a good fit for you, we offer a free consultation to help you evaluate our team."
    },
]

const Faq = () => {
    return <section id="faq" className='container mx-auto py-12 px-2'>
        {/* <div className="w-16 border-b-4 border-gray-200 mb-4"></div> */}
        <h2 className="mb-12 text-gold-400">Frequently Asked Questions</h2>

        <div className="masonry md:masonry-2">
            {items.map((item) => (
                <div className='pb-10 break-inside'>
                    <h6 className='pb-2 text-xl'>{item.question}</h6>
                    <p className='text-gray-500'>{item.answer}</p>
                </div>
            ))}
        </div>
    </section>;
};

export default Faq;
