import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { z } from "zod";

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

const phoneRegex = /[0-9]{3}-?[0-9]{3}-?[0-9]{4}/;

// Define the schema using zod
const schema = z.object({
    Name: z.string().min(1, "Name is required"),
    Email: z.string().email("Enter a valid email"),
    // Phone: z.string().regex(/^\d{10}$/, "Enter a valid phone number"),
    Phone: z.string().regex(phoneRegex, 'Enter a valid phone number'),
    Message: z.string(),
    Newsletter: z.boolean(),
});

const ContactForm3 = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm({
        resolver: zodResolver(schema),
    });

    const onSubmit = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.GATSBY_DIRECTUS_URL}/items/Contact_Form`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        // Authorization: `Bearer ${process.env.DIRECTUS_TOKEN}`,
                    },
                }
            );
            console.log(response.data);
            setIsSubmitted(true);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    return (
        <div className="m-8">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex">
                    <label htmlFor="Name">Name</label>
                    {errors.Name && (
                        <span role="alert" className="ml-4 text-sm text-gold-500">
                            {errors.Name.message}
                        </span>
                    )}
                </div>
                <input
                    id="Name"
                    className="mb-4 rounded-md border-gray-3"
                    type="text"
                    placeholder=""
                    aria-invalid={errors.Name ? "true" : "false"}
                    {...register("Name")}
                />

                <div className="flex">
                    <label htmlFor="Email">Email</label>
                    {errors.Email && (
                        <span role="alert" className="ml-4 text-sm text-gold-500">
                            {errors.Email.message}
                        </span>
                    )}
                </div>
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none mb-4">
                        <EnvelopeIcon className="h-6 w-6 text-gray-400 my-2 mx-3" />
                    </div>
                    <input
                        id="Email"
                        className="mb-4 rounded-md border-gray-3 w-full pl-12"
                        type="text"
                        placeholder=""
                        aria-invalid={errors.Email ? "true" : "false"}
                        {...register("Email")}
                    />
                </div>

                <div className="flex">
                    <label htmlFor="Phone">Phone</label>
                    {errors.Phone && (
                        <span role="alert" className="ml-4 text-sm text-gold-500">
                            {errors.Phone.message}
                        </span>
                    )}
                </div>
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none mb-4">
                        <PhoneIcon className="h-6 w-6 text-gray-400 my-2 mx-3" />
                    </div>
                    <input
                        id="Phone"
                        className="mb-4 rounded-md border-gray-3 w-full pl-12"
                        type="text"
                        placeholder=""
                        aria-invalid={errors.Phone ? "true" : "false"}
                        {...register("Phone")}
                    />
                </div>

                <label for="Message">Message</label>
                <textarea id="Message" className='mb-4 rounded-md border-gray-300 w-full' {...register("Message")} />
                <div className="flex space-x-2 items-center mb-4">
                    <input id="Newsletter" className='' type="checkbox" placeholder="Sign up for newsletter" {...register("Newsletter")} />
                    <label for="Newsletter" className='mt-1'>Sign up for newsletter</label>
                </div>

                <button type="submit" className="button-wide mt-2 disabled:bg-gray-200" disabled={isSubmitted}>Submit</button>
                {isSubmitted && (
                    <p className="mt-4 text-blue-500 text-center">Thank you for contacting us.</p>
                )}
            </form>
        </div>
    );
};

export default ContactForm3;