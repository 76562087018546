import React from 'react'
import {
    LocationMarkerIcon,
    UserGroupIcon,
    CalendarIcon,
    SearchIcon,
} from '@heroicons/react/24/outline'

const steps = [
    {
        title: "Establish",
        img: {},
        description: "We start with an engagement meeting to establish our relationship. There is no cost or obligation for this meeting.",
        icon: LocationMarkerIcon,
    },
    {
        title: "Envision",
        img: {},
        description: "We follow up with the purpose meeting, where we discuss your purpose and goals for your life.",
        icon: UserGroupIcon,
    },
    {
        title: "Execute",
        img: {},
        description: "In the implementation meeting, we discuss, refine, and implement the financial plan.",
        icon: CalendarIcon,
    },
    {
        title: "Examine",
        img: {},
        description: "On an ongoing basis, we review your progress toward your goals and adjust the plan as needed.",
        icon: SearchIcon,
    },
]

const Timeline = () => {
    return (
        <section className='py-12'>

            <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 flex-shrink-0 group-hover:scale-110">
                <div className="flex flex-col items-center">
                    <p className="text-xs uppercase leading-none font-semibold text-blue-500">step</p>
                    <p className="text-2xl leading-none font-black text-blue-500">01</p>
                </div>
            </div>

        </section>
    )
}

export default Timeline