import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import NewHeader from '../components/sections/NewHeader'
import Faq from '../components/sections/Faq'
import Contact from '../components/sections/Contact'
import Tabs2 from '../components/sections/Tabs2'
import ContactForm from '../components/forms/ContactForm'
import Button from '../components/elements/Button'
import Steps from '../components/sections/Steps'
import Timeline from '../components/sections/Timeline'
import ContactForm3 from '../components/forms/ContactForm3'
import { BuildingOfficeIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

const whattoexpect = () => {
    return (
        <Layout>
            {/* <div className="bg-gray-200"> */}
            <Seo title="What to Expect" />

            {/* <Tabs2 /> */}
            {/* <Contact /> */}

            <section className="bg-blue-50 lg:h-[550px] lg:my-36">
                <div className='container mx-auto right-0 left-0 lg:absolute lg:-my-12 lg:flex justify-between px-4 pb-12'>
                    <div className="lg:pt-24 pt-12 px-4 lg:px-0 pb-12 lg:pb-0">
                        <h2 className="mb-6 text-blue-600">Get in Touch</h2>
                        <p className='mb-12 text-blue-600 max-w-3xl mr-4'>
                            We dedicate ourselves each day to the joyful work of leading our clients to plan well financially, live life abundantly, and give lavishly of themselves. We would be honored to serve you.
                        </p>
                        <div className="flex space-x-4 align-top mb-4">
                            <BuildingOfficeIcon className='h-6 w-6 text-blue-400' />
                            <p><a className='text-blue-600' href="https://goo.gl/maps/sH2F9Xg1ZZFT1Nk28" target="_blank"> 3300 S Broadway Ave Suite 102 <br /> Tyler, TX 75701</a></p>
                        </div>
                        <div className="flex space-x-4 align-top mb-6">
                            <EnvelopeIcon className='h-6 w-6 text-blue-400' />
                            <p><a className='text-blue-600' href="mailto:info@rosemanwealth.com">info@rosemanwealth.com</a></p>
                        </div>
                        <div className="flex space-x-4 align-top mb-4">
                            <PhoneIcon className='h-6 w-6 text-blue-400' />
                            <p><a className='text-blue-600' href="tel:9037473911">(903) 747-3911</a></p>
                        </div>
                    </div>
                    <div id="form-card" className="right-0 w-full max-w-[500px] bg-white shadow-md rounded-2xl border-2 border-gray-100">
                        <a href='https://calendly.com/rosemanwealthadvisors/initialmeeting' target='_blank'>
                            <div className="m-8 border border-gray-300 rounded-md p-2 text-center hover:border-blue-500 hover:bg-blue-50 group">
                                <p className='text-sm font-medium text-gray-700 group-hover:text-blue-500'>Schedule Consultation</p>
                            </div>
                        </a>
                        <div className="mx-auto flex">
                            <div className=" border-gray-200 w-full flex-auto align-middle mr-4 border h-0 my-auto"></div>
                            <small className="flex-none">or</small>
                            <div className=" border-gray-200 w-full flex-auto align-middle ml-4 border h-0 my-auto"></div>
                        </div>
                        <ContactForm3 />
                    </div>

                </div>
            </section>
            <Faq />
            {/* </div> */}
        </Layout>
    )
}

export default whattoexpect
